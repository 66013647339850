<template>
  <div>
    <el-card>
      <div class="menu-box">
        <h2>机构审核管理</h2>
        <div class="box-bd">
          <el-row :gutter="10">
            <el-col :span="3">
              <el-button type="warning" @click="dialogLog.state = true"
                >查看日志</el-button
              >
            </el-col>
            <el-col :span="18">
              <el-alert
                title="您还有未处理的审核信息，请及时处理"
                type="error"
                v-if="showalert"
                :closable="false"
              >
              </el-alert
            ></el-col>
          </el-row>
          <el-table
            class="approval-table"
            ref="approvalRef"
            :data="approvalList"
            :row-key="getRowKeys"
          >
            <el-table-column prop="business_name" label="机构名称" />

            <el-table-column prop="business_code" label="机构代码" />
            <el-table-column prop="check_user" label="审核人" />
            <el-table-column prop="create_time" label="申请时间" />
            <el-table-column prop="remark" label="备注" />

            <el-table-column prop="status" label="审批状态">
              <template slot-scope="scope">
                <!-- <el-switch
                v-model="scope.row.state"
                :active-value="1"
                :inactive-value="2"
                @change="changeState(scope.row)"
              /> -->

                <el-tag v-if="scope.row.application_status === 1" type="success"
                  >已通过</el-tag
                >
                <el-tag v-if="scope.row.application_status === -1" type="danger"
                  >已拒绝</el-tag
                >
                <el-tag v-if="scope.row.application_status === 0" type="warning"
                  >待审核</el-tag
                >
              </template>
            </el-table-column>

            <el-table-column label="操作" width="300">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.application_status === 0"
                  size="mini"
                  type="success"
                  icon="el-icon-check"
                  @click="pass(scope.row)"
                  >通过</el-button
                >

                <el-button
                  v-if="scope.row.application_status === 0"
                  size="mini"
                  type="danger"
                  icon="el-icon-close"
                  @click="decline(scope.row)"
                  >拒绝</el-button
                >
                <el-button
                  size="mini"
                  icon="el-icon-search"
                  @click="openInfo(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- fenye -->
          <el-pagination
            :page-sizes="[2, 5, 10]"
            :pager-count="15"
            style="margin:15px 0 15px 15px;"
            layout="total, sizes, prev, pager, next"
            :page-size="query.limit"
            background
            :total="exp.num"
            @current-change="pageChange"
            @size-change="sizeChange"
          />
        </div>
      </div>
    </el-card>
    <!-- 详情-->
    <el-dialog
      title="机构详情"
      :before-close="handleClose"
      :visible.sync="addDialogVisible"
      width="80%"
    >
      <el-descriptions>
        <el-descriptions-item label="机构名称">{{
          row.business_name
        }}</el-descriptions-item>

        <el-descriptions-item label="机构编号">{{
          approvalInfo.business_code
        }}</el-descriptions-item>
        <el-descriptions-item label="申请时间">{{
          approvalInfo.create_time
        }}</el-descriptions-item>
        <el-descriptions-item label="审核人">{{
          row.user
        }}</el-descriptions-item>

        <el-descriptions-item label="审批状态">
          <el-tag
            size="mini"
            v-if="approvalInfo.application_status === 1"
            type="success"
            >已通过</el-tag
          >

          <el-tag
            size="mini"
            v-if="approvalInfo.application_status === -1"
            type="danger"
            >已拒绝</el-tag
          >
          <el-tag
            size="mini"
            v-if="approvalInfo.application_status === 0"
            type="warning"
            >待审核</el-tag
          >
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAdd()">取 消</el-button>
        <el-button type="primary" @click="confirmAdd()">确 认</el-button>
      </span>
    </el-dialog>
    <all-log :dialogLog="dialogLog" />
    <audio src="../../../assets/sound/sound1.mp3" ref="audio" muted></audio>
  </div>
</template>

<script>
import {
  inboundApprovalList,
  getInboundApprovalSearchFields,
  getInboundApprovalSearchboxList,
  inboundApprovalInfo,
  approvalPass,
  refuseApproval,
} from '@/api/psi/whapproval.js'
import {
  applicationList,
  applicationInfo,
  applicationCancel,
  applicationPass,
  businessList,
} from '@/api/member/institution.js'
export default {
  data() {
    return {
      showalert: false,
      dialogLog: {
        state: false,
      },
      approvalStatus: [
        {
          value: 0,
          name: '待审批',
        },
        {
          value: 1,
          name: '审批通过',
        },
        {
          value: 2,
          name: '审批拒绝',
        },
        {
          value: 'all',
          name: '状态不限',
        },
      ],
      visible: false,
      approvalList: [],
      query: {
        // 跳转页码
        page_code: 1,
        // 每页几条数据
        page_num: 10,
        // 当前页数
        page: 1,
        where_list: {
          state: '',
          add_time: '',
          mp_name: '',
          number: '',
          numbering: '',
        },
      },
      exp: {
        // 总条数
        num: 0,
        // 分页页码
        page: 1,
        // 当前页
        count: 1,
      },
      addDialogVisible: false,
      approvalInfo: {},
      checkedItem: [],
      row: {},
      ids: [],
      searchFields: {},
      timer: null,
    }
  },
  created() {
    this.getPollingList()
    this.getInboundApprovalSearchFields()
  },
  destroyed() {
    window.clearInterval(this.timer)
  },
  watch: {
    approvalList: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal.length !== 0) {
          newVal.forEach((item) => {
            if (item.application_status === 0) {
              this.showalert = true
            }
          })
        }
      },
    },
  },
  methods: {
    getPollingList() {
      this.timer = window.setInterval(() => {
        setTimeout(() => {
          this.getApprovalList()
        }, 0)
      }, 3000)
    },
    async getInboundApprovalSearchFields() {
      const { data: res } = await getInboundApprovalSearchFields()
      console.log(res)
      this.searchFields = res.data
    },
    async getApprovalList() {
      const { data: res } = await applicationList(this.query)
      //console.log(res)
      this.approvalList = res.data.data
      let num = this.$store.state.institution_number
      //console.log(num)
      if (num !== this.approvalList.length && num !== 0) {
        //消息数量有变化
        // console.log('消息数量有变化')

        this.$message({
          message: '有新的机构申请，请及时处理',
          type: 'warning',
          duration: 100000,
        })
        this.$refs['audio'].play()
        //this.$sound.playSound('../../../assets/sound/sound1.mp3')
      } else {
        // 消息数量没变化
        //console.log('消息数量没变化')
      }
      this.$store.commit('setInstitutionNumber', this.approvalList.length)

      this.exp.num = res.data.total
    },
    // 页面改变监听事件
    pageChange(value) {
      // this.query.page为当前页
      this.query.page = value
      this.getApprovalList()
    },
    // 页面每一页条数改变事件
    sizeChange(value) {
      this.query.page_num = value
      this.getApprovalList()
    },
    selectable(row) {
      //console.log(row)
      if (row.status === 0) {
        return true
      } else {
        return false
      }
    },
    multipleApproval() {
      this.ids = []
      if (this.checkedItem.length === 0) {
        this.$message.warning('批量同意入库审批：请选择需要同意的审批申请！')
      } else {
        this.$confirm('确认批量通过入库申请?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info',
        })
          .then(async () => {
            this.checkedItem.forEach((item) => {
              this.ids.push(item.id)
            })
            const res = await approvalPass({ ids: this.ids })
            if (res.data.code === 1) {
              this.$message.success('已批量通过入库申请!')
              this.$refs.approvalRef.clearSelection()
              this.getApprovalList()
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消通过',
            })
          })
      }
    },
    getRowKeys(row) {
      //   console.log(row)
      // this.rowKeyArr.push(row.id)
      return row.id
    },
    // 通过
    async pass(row) {
      this.$confirm('确认通过?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
      })
        .then(async () => {
          let formData = new FormData()
          formData.append('application_id', row.application_id)
          const res = await applicationPass(formData)
          if (res.data.code === 1) {
            this.$message.success('已通过!')

            this.$refs.approvalRef.clearSelection()
            this.getApprovalList()
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消通过',
          })
        })
    },
    // 拒绝
    async decline(row) {
      this.$prompt('请输入拒绝原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async ({ value }) => {
          const res = await applicationCancel({
            application_id: row.application_id,
            remark: value,
          })
          if (res.code === 1) {
            this.$message.success('已拒绝!')
            this.$refs.approvalRef.clearSelection()

            this.getApprovalList()
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消拒绝',
          })
        })
    },

    // 打开详情
    async openInfo(row) {
      this.addDialogVisible = true
      let formData = new FormData()
      formData.append('account_id', row.account_id)
      const res = await applicationInfo(formData)
      console.log(res)
      this.approvalInfo = res.data.data
      this.row = row
    },
    handleClose(row) {
      this.addDialogVisible = false
      this.approvalInfo = {}
    },
    handleChange(val) {
      console.log(val)
      this.checkedItem = val
    },
    searchList() {
      let time = this.query.where_list.add_time
      if (time.length === 2) {
        time = time[0] + '--' + time[1]
        this.query.where_list.add_time = time
      }
      this.getApprovalList()
      this.visible = false
    },
  },
}
</script>

<style scoped>
.approval-table >>> .el-checkbox {
  zoom: 200% !important;
}
</style>
