import { post } from '@/utils/request'

// 机构审核列表
export function applicationList(data) {
    return post('/admin/business/applicationList', data)
}

// 审核详情
export function applicationInfo(data) {
    return post('/admin/business/applicationInfo', data)
}
// 拒绝
export function applicationCancel(data) {
    return post('/admin/business/applicationCancel', data)
}
// 同意
export function applicationPass(data) {
    return post('/admin/business/applicationPass', data)
}
//机构列表

export function businessList(data) {
    return post('/admin/business/businessList', data)
}
